import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '~/src/redux/root-reducer';
import { StateModel } from '~/src/models/state.model';

export const createStore = (initialState?: StateModel) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) => [
            ...getDefaultMiddleware({
                serializableCheck: false,
            }),
        ],
    });
};

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
